<template>
  <div class="technology">
    <div class="technology-top">
      <div class="national-karaoke part-container">
        <div class="karaoke-title container-title">
          AI换曲风
        </div>
        <div class="title-describe">
          <div class="describe-left">
            “百搭伴奏，风格随心”
          </div>
          <div class="describe-right">
            01/03
          </div>
        </div>
        <div class="content">
          <div class="playMusic" v-for="(item, index) in musicArr" :key="index">
            <img class="music-background" :src="item.imgSrc">
            <img class="music-background2" :src="item.imgSrcHover">
            <audio class="allAudio" :src="item.audioSrc" @timeupdate="update(index)"></audio>
            <div class="playFlag">
              <img v-if="!item.playFlag" src="../assets/technology-image/playIcon.png" @click="playAllMusic(index)"/>
              <img v-if="item.playFlag" src="../assets/technology-image/pauseIcon.png" @click="playAllMusic(index)"/>
            </div>
          </div>
        </div>
        <div class="content-describe">
          我们将AI音乐信息检索技术与AI编曲技术结合起来，形成了独特的歌曲“换曲风”玩法。“百搭伴奏，风格随心”，从2020年起，该功能在国内最大在线K歌平台获上亿次使用，广受用户好评。该功能后续也发布在日本最大的在线K歌平台，以高质量音乐内容获得了海外用户的认可。
        </div>
      </div>
      <div class="ump part-container">
        <div class="container-title">
          UMP
        </div>
        <div class="title-describe">
          <div class="describe-left">
            让每一首音乐作品的知识得以完整呈现
          </div>
          <div class="describe-right">
            02/03
          </div>
        </div>
        <div class="content">
          <img src="../assets/technology-image/ump.png" class="none-hover"/>
          <img src="../assets/technology-image/ump-hover.png" class="hover"/>
        </div>
        <div class="content-describe">
          通过AI识别与人工标注相结合的方式，我们拥有了全球最精确的华语歌曲音乐信息库。该信息库准确、全面地记录了从旋律、歌词到和弦、曲式等音乐信息，全面推动内部技术和音乐创意应用乃至教育的发展。
        </div>
      </div>
    </div>
    <div class="technology-bottom">
      <div class="bottom-title">
        <div class="part-container">
          <div class="container-title">
            AI音乐制作人伶伦
          </div>
          <div class="title-describe">
            <div class="describe-left">
              昔黄帝令伶伦作为律。——《吕氏春秋·仲夏纪·古乐》
            </div>
            <div class="describe-right">
              03/03
            </div>
          </div>
          <div class="content-describe">
            我们将AI作词、作曲、编曲以及音频渲染技术串联起来，形成了我们的AI音乐制作人“伶伦”（Linglun）。在广泛而充分地学习后，我们相信伶伦将成为元宇宙时代的最具有创造性的“音乐制作人”。
          </div>
          <img src="../assets/technology-image/Vector 75.png"/>
        </div>
      </div>
      <div class="bottom-demo">
        <div class="demo-left">
          <div class="left-title">
            人民日报x清华大学
          </div>
          <div class="content">
            <video class="allVideo" style="width= 100%; height=100%; object-fit: fill;" src="https://download.lazycomposer.com/product/official_website/linglun.mp4" poster="../assets/technology-image/tsinghua.png" @play="handlePlay(1)" controls></video>
          </div>
          <div class="link">
            由Linglun改编的<a target="_blank" href="https://weibo.com/2803301701/Kmhs1FTp9?refer_flag=1001030103_">《没有共产党就没有新中国》</a>获100万+点击量<br/>
          </div>
        </div>
        <div class="demo-right">
          <div class="right-title">
            石景山团区委志愿者歌曲
          </div>
          <div class="play-music">
            <div class="background-image">
              <img src="../assets/technology-image/volunteer.png"/>
              <img src="../assets/technology-image/volunteer-hover.png"/>
              <img v-show="!playFlag" class="playIcon" src="../assets/technology-image/playIcon.png" @click="playAllMusic(3)"/>
              <img v-show="playFlag" class="pauseIcon" src="../assets/technology-image/pauseIcon.png" @click="playAllMusic(3)"/>
            </div>
            <audio class="allAudio" id="volunteerAudio" src="https://download.lazycomposer.com/product/official_website/MMMFinal3.mp3" @timeupdate="update(3)"></audio>
            <div class="slider">
              <a-slider class="slider-container" :tip-formatter="null" v-model="playerProgress" @change="onChange" @afterChange="onAfterChange" />
              <span>{{audioDuration | delayDuration}}</span>
            </div>
          </div>
          <div class="music-describe">
            石景山团区委志愿者歌曲“老街新声”制作
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  data: () => ({
    audioDuration: 0, // 音频时长
    playerProgress: 0, // 播放进度
    playFlag: false, // 是否播放
    videoFlag: -1,
    musicArr: [
      {
        imgSrc: require("../assets/technology-image/k-1.png"),
        imgSrcHover: require("../assets/technology-image/k-1-hover.png"),
        audioSrc: "https://download.lazycomposer.com/product/official_website/RnB-preview.mp3",
        playFlag: false
      },
      {
        imgSrc: require("../assets/technology-image/k-2.png"),
        imgSrcHover: require("../assets/technology-image/k-2-hover.png"),
        audioSrc: "https://download.lazycomposer.com/product/official_website/China-preview.mp3",
        playFlag: false
      },
      {
        imgSrc: require("../assets/technology-image/k-3.png"),
        imgSrcHover: require("../assets/technology-image/k-3-hover.png"),
        audioSrc: "https://download.lazycomposer.com/product/official_website/Rock-preview.mp3",
        playFlag: false
      }
    ]
  }),
  filters: {
      delayDuration(Duration) {
          let duration = parseInt(Duration)
          let min = Math.floor(duration / 60);
          let sec = (duration % 60) > 10 ? (duration % 60) : "0"+(duration % 60);
          return min + ":" + sec;
      }
  },
  mounted() {
    this.getDuration()
  },
  methods: {
    handlePlay(index) {
      let AllMusicArr = document.getElementsByClassName("allAudio");
      for(let i = 0; i < AllMusicArr.length; i++) {
        if(!AllMusicArr[i].paused) {
          AllMusicArr[i].pause()
        }
      }
    }, 
    playAllMusic(index) {
      let AllMusicArr = document.getElementsByClassName("allAudio");
      let AllVideoArr = document.getElementsByClassName("allVideo");
      for(let i = 0; i < AllMusicArr.length; i++) {
        if(!AllMusicArr[i].paused) {
          AllMusicArr[i].pause()
        }
      }
      for(let j = 0; j < AllVideoArr.length; j++) {
        AllVideoArr[j].pause()
      }

      if(index == 3) {
        if(!this.playFlag) {
          AllMusicArr[3].play()
        }else {
          AllMusicArr[3].pause();
        }
      }else {
        if(!this.musicArr[index]['playFlag']) {
          AllMusicArr[index].play();
        }else {
          AllMusicArr[index].pause();
        }
      }
    },
    update(index) {
      let AllMusicArr = document.getElementsByClassName("allAudio");
      if(index == 3) {
        this.playerProgress = (AllMusicArr[3].currentTime / this.audioDuration) * 100;
        this.playFlag = !AllMusicArr[3].paused
      } else {
        this.musicArr[index]['playFlag'] = !AllMusicArr[index].paused
      }
    },

    getDuration() {
      let audio = document.getElementById("volunteerAudio");
      audio.oncanplay = () => {
        this.audioDuration = audio.duration
      }
    },
    onChange(checked){
      // this.playerProgress = checked;
      // let audio = document.getElementById("volunteerAudio");
      // audio.currentTime = this.playerProgress * this.audioDuration / 100;
    },
    onAfterChange(checked) {
      this.playerProgress = checked;
      let audio = document.getElementById("volunteerAudio");
      audio.currentTime = this.playerProgress * this.audioDuration / 100;
    }
  }
}
</script>

<style scoped lang="less">
@media screen and (min-width: 1200px){
  .technology{
    margin-left: calc((100vw - 1200px) / 2);
  }
}
.technology{
  width:1200px;
  height: 1740px;
  color: #fff;
  padding: 0 100px 0 100px;
  .technology-top{
    display: flex;
    justify-content: space-between;
    .national-karaoke{
      width: 414px !important;
      margin-top: 82px;
      .container-title{
        font-family: PangMenZhengDao;
        font-style: normal;
        font-weight: normal;
        font-size: 56px;
        line-height: 44px;
        color: rgba(255, 255, 255, 0.95);
      }
      .title-describe{
        margin-top: 16px;
        display: flex;
        justify-content: space-between;
        .describe-left{
          font-family: Hanson;
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 24px;
          color: rgba(255, 255, 255, 0.65);
        }
        .describe-right{
          font-family: PingFang SC;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 21px;
          color: rgba(255, 255, 255, 0.45);
        }
      }
      .content{
        margin-top: 28px;
        display: flex;
        .playMusic{
          margin-right: 16px;
          width: 72px;
          height: 72px;
          .music-background{
            display: block;
          }
          .music-background2{
            display: none;
          }
        }
        .playMusic:hover{
          .music-background2{
            display: block;
          }
          .music-background{
            display: none;
          }
        }
        .music-background{
          width: 72px;
          height: 72px;
          border-radius: 10px;
        }
        .playFlag{
          margin-top: -60px;
          margin-left: 12px;
          width: 48px;
          height: 48px;
          img{
            cursor: pointer;
            width: 48px;
            height: 48px;
          }
        }
      }
      .content-describe{
        margin-top: 40px;
        padding: 16px 0;
        font-family: PingFang SC;
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.2px;
        color: rgba(255, 255, 255, 0.65);
        border-top: 1px solid #303030;
        border-bottom: 1px solid #303030;
      }
    }
    .ump{
      margin-top: 314px;
      width: 452px;
      .container-title{
        font-family: PangMenZhengDao;
        font-style: normal;
        font-weight: normal;
        font-size: 56px;
        line-height: 44px;
        color: rgba(255, 255, 255, 0.95);
      }
      .title-describe{
        margin-top: 16px;
        display: flex;
        justify-content: space-between;
        .describe-left{
          font-family: Hanson;
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 24px;
          color: rgba(255, 255, 255, 0.65);
        }
        .describe-right{
          font-family: PingFang SC;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 21px;
          color: rgba(255, 255, 255, 0.45);
        }
      }
      .content{
        margin-top: 24px;
        width: 452px;
        height: 275px;
        img{
          width: 452px;
          height: 275px;
        }
        .none-hover{
          display: block;
        }
        .hover{
          display: none;
        }
      }
      .content:hover{
        .hover{
          display: block;
        }
        .none-hover{
          display: none;
        }
      }
      .content-describe{
        margin-top: 40px;
        padding: 16px 0;
        font-family: PingFang SC;
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.2px;
        border-top: 1px solid #303030;
        border-bottom: 1px solid #303030;
        color: rgba(255, 255, 255, 0.65);
      }
    }
  }
  .technology-bottom{
    margin-top: -20px;
    .bottom-title{
      width: 452px;
      .container-title{
        font-family: PangMenZhengDao;
        font-style: normal;
        font-weight: normal;
        font-size: 56px;
        line-height: 44px;
        color: rgba(255, 255, 255, 0.95);
      }
      .title-describe{
        margin-top: 16px;
        display: flex;
        justify-content: space-between;
        .describe-left{
          font-family: Hanson;
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 24px;
          color: rgba(255, 255, 255, 0.65);
        }
        .describe-right{
          font-family: PingFang SC;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 21px;
          color: rgba(255, 255, 255, 0.45);
        }
      }
      .content-describe{
        margin-top: 40px;
        padding: 16px 0;
        font-family: PingFang SC;
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.2px;
        border-top: 1px solid #303030;
        border-bottom: 1px solid #303030;
        color: rgba(255, 255, 255, 0.65);
      }
      img{
        width: 120px;
        height: 94px;
        margin-left: 393px;
        margin-top: -10px;
      }
    }
    .bottom-demo{
      margin-top: -48px;
      display: flex;
      .demo-left{
        width: 452px;
        .left-title{
          font-family: PangMenZhengDao;
          font-style: normal;
          font-weight: normal;
          font-size: 36px;
          line-height: 44px;
          color: rgba(255, 255, 255, 0.65);
        }
        .content{
          margin-top: 24px;
          width: 452px;
          height: 275px;
          video{
            width: 452px;
            height: 275px;
          }
        }
        .link{
          margin-top: 40px;
          padding: 16px 0;
          font-family: PingFang SC;
          font-style: normal;
          font-weight: 300;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.2px;
          border-top: 1px solid #303030;
          border-bottom: 1px solid #303030;
          color: rgba(255, 255, 255, 0.65);
          a{
            color: rgba(255, 255, 255, 0.65);
            text-decoration: underline;
          }
        }
      }
      .demo-right{
        margin-left: 96px;
        width: 414px;
        .right-title{
          font-family: PangMenZhengDao;
          font-style: normal;
          font-weight: normal;
          font-size: 36px;
          line-height: 44px;
          color: rgba(255, 255, 255, 0.65);
        }
        .play-music{
          width: 100%;
          display: flex;
          margin-top: 24px;
          .background-image{
            img:nth-child(1) {
              display: block;
              width: 72px;
              height: 72px;
            }
            img:nth-child(2){
              display: none;
            }
          }
          .background-image:hover{
            img:nth-child(2) {
              display: block;
              width: 72px;
              height: 72px;
            }
            img:nth-child(1){
              display: none;
            }
          }
          .playIcon {
            cursor: pointer;
            margin-top: -98px;
            margin-left: 12px;
            width: 48px;
            height: 48px;
          }
          .pauseIcon {
            cursor: pointer;
            margin-top: -98px;
            margin-left: 12px;
            width: 48px;
            height: 48px;
          }
          .slider{
            width: 326px;
            height: 72px;
            line-height: 72px;
            display: flex;
            background: #202020;
            border-radius: 10px;
            width: 326px;
            margin-left: 16px;
            .slider-container{
              margin-top: 30px;
            }
            span{
              margin-left: 16px;
              font-family: PingFang SC;
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              display: flex;
              align-items: center;
              letter-spacing: 0.2px;
              color: rgba(255, 255, 255, 0.65);
            }
          }
        }
        .music-describe{
          margin-top: 20px;
          padding: 16px 0;
          font-family: PingFang SC;
          font-style: normal;
          font-weight: 300;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.2px;
          border-top: 1px solid #303030;
          border-bottom: 1px solid #303030;
          color: rgba(255, 255, 255, 0.65);
        }
      }
    }
  }
}
</style>

<style>
.slider .ant-slider{
  width: 240px;
  margin-left: 20px;
}
.slider .ant-slider-rail{
    background: #303030 !important;
}
.slider .ant-slider-track{
    background:  rgba(255, 255, 255, 0.65) !important;
}
.slider .ant-slider-handle{
    width: 0px !important;
    height: 0px !important;
    border: none;
    box-shadow: none !important;
}
</style>
