<template>
  <div id="app">
    <div id="nav">
      <router-link to="/home" class="home-link">
        <i class="iconfont icon-logo" style="margin-right: 8px;position:relative; top: 6px;"></i>
        <span>北京灵动音科技有限公司</span>
      </router-link>
      <router-link to="/introduce">产品介绍</router-link>
      <router-link to="/technology">音乐技术</router-link>
      <router-link to="/about">关于我们</router-link>
      <router-link to="/contact">联系我们</router-link>
    </div>
    <router-view/>
  </div>
</template>
<script>
export default {
  mounted() {
    console.log(" ____   ____    ____    ______     __     __   _   _   ______   _  _______ ")
    console.log("|  _  ╲|  ___ ╲|  ___ ╲|  __  |    |  ╲   / | | | | | |  ____| |_| | ____| ")
    console.log("| | |  |  |___ |  |___ | |_/ /     |  .  .  | | | | | | |____   _  | |     ")
    console.log("| | |  |   ___||   ___||  __/      |  |╲/|  | | | | | |____  | | | | |     ")
    console.log("| |_|  |  |___ |  |___ | |         |  |  |  | | |_| |  ___|  | | | | |____ ")
    console.log("|  _  / ╲ ____/ ╲ ____/╲_|         |__|  |__/  ╲____| |______| |_| |______|")
  },
}
</script>


<style lang="less">
#app {
  width: 100vw;
  min-width: 1200px;
  min-height: 100vh;
  background: #141414;
}
@media screen and (min-width: 1200px){
    #nav{
      margin-left: calc((100vw - 1200px) / 2);
    }
}
#nav {
  // border: 1px solid red;
  z-index: 10;
  width: 1200px;
  padding: 27px 0 27px 0;
  a {
    height: 34px;
    display: inline-block;
    text-decoration: none;
    font-weight: bold;
    margin-right: 56px;
    font-family: PingFang SC;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 34px;
    color: rgba(255, 255, 255, 0.65);
    &.router-link-exact-active {
      color: #5A85FD;
    }
  }
  .home-link{
    position: relative;
    span{
      font-weight: 900;
    }
    i{
      line-height: 10px;
      position: relative;
      top: 5px;
      font-size: 30px;
      margin-right:16px;
      // font-weight: 600;
    }
  }
}
</style>
