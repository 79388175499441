<template>
  <div class="introduce">
    <div class="list-container">
      <ul id="list-ul"></ul>
    </div>
    <div class="chordMate-container">
      <div class="allBackground">
        <img src="../assets/introduce-image/background.png" />
      </div>
      <div class="chordMate-content">
        <div class="content-top">
          <div class="content-left">
            <img src="../assets/introduce-image/chordMate.png" />
            <div class="describe describe-one">
              <!-- <span>7</span>种乐器，匹配强大的AI引擎<br />
              让你的创意瞬间得到回应 -->
            </div>
            <div class="describe describe-two">
              <!-- <span>4</span>种和弦输入方式，<br />
              记录每个稍纵即逝的灵感 -->
            </div>
          </div>
          <div class="content-right">
            <img src="../assets/introduce-image/partOne-iPhone.png" />
            <div class="video-container">
              <video class="allVideo" @play="handlePlay(0)" controls
                src="https://www.lazycomposer.com/static/chordmate.mp4">
              </video>
            </div>
          </div>
        </div>
        <div class="content-bottom">
          <div class="bottom-title">
            <img src="../assets/introduce-image/chordMate-title.png" />
          </div>
          <div class="title-describe">Product introduction</div>
          <div class="split-line"></div>
          <div class="chordMate-content">
            <div class="content-first">
              和弦派是一款基于AI音乐生成引擎，为音乐人和音乐爱好者提供智能创作、编曲和练习的随身音乐工作站。
            </div>
            <div class="content-paragraph">
              <div class="paragraph-title">
                快速创作歌曲：
              </div>
              <div class="paragraph-intro">
                用户可通过输入歌词自动写歌、选择风格模板快速生成特定风格歌曲，也可以自定义参数创建个性化乐谱工程。
              </div>
            </div>
            <div class="content-paragraph">
              <div class="paragraph-title">
                精准编辑歌曲：
              </div>
              <div class="paragraph-intro">
                如同编辑照片般细腻，用户对歌曲的整体风格至每个伴奏乐器细节，均能实现精准把控与自由调整。
              </div>
            </div>
            <div class="content-paragraph">
              <div class="paragraph-title">
                一键导出渲染：
              </div>
              <div class="paragraph-intro">
                支持导出MP3、WAV格式的并轨渲染音频以及分轨WAV文件，同时可以一键导出词曲或工程MIDI文件。
              </div>
            </div>
            <div class="content-paragraph">
              <div class="paragraph-title">
                便捷的使用方式:
              </div>
              <div class="paragraph-intro">
                AI体验尽在指尖，随时随地打开手机即可使用，一款真正的音乐人和音乐爱好者的随身工作站。
              </div>
            </div>
          </div>
          <div class="know-more" @click="openOverLay">
            立即获取
            <i class="iconfont icon-lejiegengduo"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="timbreTool-container">
      <div class="allBackground">
        <img src="../assets/introduce-image/background.png" />
      </div>
      <div class="timbreTool-content">
        <div class="timbreTool-top">
          <img src="../assets/introduce-image/timbreTool.png" />
        </div>
        <div class="timbreTool-bottom" id="vst">
          <div class="bottom-title">
            <img src="../assets/introduce-image/timbreTool-title.png"/>
          </div>
          <div class="title-describe">Product introduction</div>
          <p class="split-line"></p>
          <div class="function-description">
            我们针对和弦派的MIDI格式开发了PC端音源，和弦派SVIP会员可以登录并在宿主使用和弦派的所有音色。<br/>
            该音源针对和弦派生成的MIDI格式进行了适配，您可以将和弦派导出的工程MIDI一键导入宿主，该音源会自动进行音色与MIDI的对应，方便您在宿主进行精细的调整与创作。
          </div>
          <div class="knowMore" @click="openTimbreTool">
            点击下载
            <i class="iconfont icon-lejiegengduo"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="introduce-partOne">
      <div class="partOne-background allBackground">
        <img src="../assets/introduce-image/background.png" />
      </div>
      <div class="partOne-container">
        <div class="partOne-top">
          <div class="partOne-left">
            <img src="../assets/introduce-image/partOne-left.png" />
            <div class="describe describe-one">
              <span>🎸</span>
              <span>7</span>个乐手,<span>17</span>种乐器随你搭配
            </div>
            <div class="describe describe-two">
              <span>🎤</span>
              <span>200+</span>种演奏方式供你调用
            </div>
          </div>
          <div class="partOne-right">
            <img src="../assets/introduce-image/partOne-iPhone.png" />
            <div class="video-container">
              <video class="allVideo" @play="handlePlay(1)" controls
                src="https://download.lazycomposer.com/product/official_website/bands.mp4"
                poster="../assets/introduce-image/partOne-right.png">
              </video>
            </div>
          </div>
        </div>
        <div class="partOne-bottom">
          <div class="bottom-title">
            <i class="iconfont icon-kou"></i>
            口袋乐队
          </div>
          <div class="title-describe">Product introduction</div>
          <p class="split-line"></p>
          <div class="function-description">
            你可以利用这个AI音乐工作站玩音乐，并发现创作就像游戏一样简单有趣。作曲、编曲、录唱、混音一气呵成，让更多非音乐专业的朋友也能释放自己的音乐想法。<br />也许你拥有音乐家的潜能，有了AI音乐引擎的支持，相信你能发现更多音乐的魅力并创造无限可能。
          </div>
          <div class="knowMore" @click="jumpPage(0)">
            立即获取
            <i class="iconfont icon-lejiegengduo"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="introduce-partTwo videoContainer">
      <div class="partTwo-background allBackground">
        <img src="../assets/introduce-image/background.png" />
      </div>
      <div class="partTwo-container">
        <div class="partTwo-top">
          <img src="../assets/introduce-image/partTwo-Safari.png" />
          <div class="video-container">
            <video class="allVideo" @play="handlePlay(2)" controls style="width= 100%; height=100%; object-fit: fill;"
              src="https://download.lazycomposer.com/product/official_website/bgmcat.mp4"
              poster="../assets/introduce-image/partTwo-vedio.png">
            </video>
          </div>
        </div>
        <div class="partTwo-bottom">
          <div class="bottom-title">
            <i class="iconfont icon-bgmcatlogo1"></i>
            BGMCAT
          </div>
          <div class="title-describe">Product introduction</div>
          <p class="split-line"></p>
          <div class="function-description">
            这是一个AI音乐生成器，它可以
            <br /><span>生成BGM：</span>
            <br />你能快速得到和视频格调匹配的背景音乐；
            <br /><span>BGM的智能化处理：</span>
            <br />它拥有调整音乐段落、高能点等关键信息的能力，轻易地同视频的节奏或事件形成准确映射。
          </div>
          <div class="knowMore" @click="jumpPage(1)">
            立即体验
            <i class="iconfont icon-lejiegengduo"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="introduce-partTwo videoContainer">
      <div class="partTwo-background allBackground">
        <img src="../assets/introduce-image/background.png" />
      </div>
      <div class="partTwo-container">
        <div class="partTwo-top">
          <img src="../assets/introduce-image/partTwo-Safari.png" />
          <div class="video-container">
            <video class="allVideo" @play="handlePlay(3)" controls style="width= 100%; height=100%; object-fit: fill;"
              src="https://download.lazycomposer.com/product/official_website/lyrica.mp4"
              poster="../assets/introduce-image/partThree.png">
            </video>
          </div>
        </div>
        <div class="partTwo-bottom">
          <div class="bottom-title">
            LYRICA
          </div>
          <div class="title-describe">Product introduction</div>
          <p class="split-line"></p>
          <div class="function-description">
            这是一个AI歌词生成器，它可以
            <br /><span>生成歌词：</span>
            <br />通过关键字（如爱情）输入，得到相关主题下的完整歌词；
            <br /><span>歌词智能化处理：</span>
            <br />1 可指定格律；2 定义韵脚；3 定义字数；4 开放逐句编辑。
          </div>
          <div class="knowMore" @click="jumpPage(2)">
            立即体验
            <i class="iconfont icon-lejiegengduo"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="introduce-partTwo">
      <div class="partTwo-background allBackground">
        <img src="../assets/introduce-image/background.png" />
      </div>
      <div class="partTwo-container">
        <div class="partTwo-top">
          <img src="../assets/introduce-image/partTwo-Safari.png" />
          <div class="video-container">
            <video class="allVideo" @play="handlePlay(4)" controls style="width= 100%; height=100%; object-fit: fill;"
              src="https://download.lazycomposer.com/product/official_website/lazycomposer.mp4"
              poster="../assets/introduce-image/partFor.png">
            </video>
          </div>
        </div>
        <div class="partTwo-bottom">
          <div class="bottom-title">
            LAZYCOMPOSER
          </div>
          <div class="title-describe">Product introduction</div>
          <p class="split-line"></p>
          <div class="function-description">
            这是一个AI作曲软件，你可以通过钢琴卷帘输入若干音符，LazyComposer的作曲算法根据这个动机为你谱出一首旋律。
          </div>
          <div class="knowMore" @click="jumpPage(3)">
            立即体验
            <i class="iconfont icon-lejiegengduo"></i>
          </div>
        </div>
      </div>
    </div>
    <qr-code v-show="overlayFlag" @closeOverlay="closeOverlay" />
    <timbre-tool v-show="overTimbreTool" @closeTimbreTool="closeTimbreTool" />
  </div>
</template>

<script>

import QrCode from './qrcode.vue';
import TimbreTool from './timbreTool.vue';
export default {
  name: 'HelloWorld',
  components: {
    QrCode,
    TimbreTool
  },
  data: () => ({
    scroll: 0,
    overlayFlag: false,
    overTimbreTool: false
  }),
  watch: {
    overlayFlag: function (val, oldVal) {
      if (val) {
        document.documentElement.style.overflow = "hidden";
      } else {
        document.documentElement.style.overflow = "scroll";
      }
    },
    overTimbreTool: function (val, oldVal) {
      if (val) {
        document.documentElement.style.overflow = "hidden";
      } else {
        document.documentElement.style.overflow = "scroll";
      }
    },

    scroll: function (val, oldVal) {
      let scrollTop
      scrollTop = val
      let index = Math.ceil(scrollTop / 170)
      let Index = parseInt(scrollTop / 850)
      let arr = document.getElementsByTagName("li");
      for (let j = 0; j < arr.length; j++) {
        arr[j].style.background = "rgba(255, 255, 255, 0.3)";
      }
      arr[index].style.background = "rgba(255, 255, 255, 0.85)"
      for (let j = 0; j < 26; j++) {
        if (j % 5 != 0) {
          arr[j].style.width = '4px';
        } else {
          arr[j].style.width = '16px';
        }
      }
      if (val - oldVal >= 0) {
        arr[Index * 5 + 2].style.width = "7px"
        arr[Index * 5 + 3].style.width = "11px"
        arr[Index * 5 + 4].style.width = "15px"
      } else {
        arr[Index * 5 + 3].style.width = "7px"
        arr[Index * 5 + 2].style.width = "11px"
        arr[Index * 5 + 1].style.width = "15px"
      }
    }
  },
  beforeMount() {
  },
  mounted() {
    this.createDom();
    window.addEventListener('scroll', this.menu);
  },
  methods: {
    closeOverlay() {
      this.overlayFlag = false;
    },
    openOverLay() {
      this.overlayFlag = true;
    },
    openTimbreTool() {
      this.overTimbreTool = true;
    },
    closeTimbreTool() {
      this.overTimbreTool = false;
    },
    jumpPage(index) {
      let hrefUrlArr = [
        "https://apps.apple.com/cn/app/%E5%8F%A3%E8%A2%8B%E4%B9%90%E9%98%9F/id1603380354",
        "https://bgmcat.com/",
        "http://dms.lazycomposer.com/demo/lyrica/",
        "https://demo.lazycomposer.com/compose/pianoSolo/",
      ]
      window.open(hrefUrlArr[index])
    },
    handlePlay(index) {
      let AllVideoArr = document.getElementsByClassName("allVideo");
      for (let i = 0; i < AllVideoArr.length; i++) {
        if (i != index) {
          AllVideoArr[i].pause();
        }
      }
    },
    createDom() {
      for (let i = 0; i < 26; i++) {
        var li = document.createElement("li");
        document.getElementById("list-ul").appendChild(li);
      }
      this.$nextTick(() => {
        let arr = document.getElementsByTagName("li");
        for (let j = 0; j < 26; j++) {
          if (j % 5 != 0) {
            arr[j].style.width = '4px';
          } else {
            arr[j].style.width = '16px';
          }
          arr[0].style.background = "rgba(255, 255, 255, 0.85)"
          arr[j].style.borderRadius = '20px';
          arr[j].style.height = '4px';
          arr[j].style.background = "rgba(255, 255, 255, 0.3)";
          arr[j].style.marginBottom = "16px";
        }
      })
    },
    menu() {
      this.scroll = document.documentElement.scrollTop || document.body.scrollTop;
      if (this.scroll > 4070) {
        this.scroll = 4070;
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
@media screen and (min-width: 1200px) {
  .chordMate-content {
    margin-left: calc((100vw - 1200px) / 2);
  }
  .timbreTool-content{
    margin-left: calc((100vw - 1200px) / 2);
  }
  .partOne-container {
    margin-left: calc((100vw - 1200px) / 2);
  }

  .partTwo-container {
    margin-left: calc((100vw - 1200px) / 2);
  }

  .list-container {
    margin-left: calc((100vw - 1200px) / 2);
  }
}

.introduce {
  width: 100vw;
  min-width: 1200px;
  background: #141414;

  .list-container {
    z-index: 10;
    position: fixed;
    top: 128px;
    left: 100px;
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      li {
        display: block;
        background: #fff;
      }
    }
  }

  .chordMate-container {
    width: 100vw;
    min-width: 1200px;
    height: 1070px;
    overflow: hidden;
    position: relative;

    .allBackground {
      position: absolute;
      z-index: 0;

      img {
        height: 912px;
      }
    }

    .chordMate-content {
      position: relative;
      z-index: 100;
      width: 1200px;
      padding: 0 100px;
      height: 1070px;

      .content-top {
        height: 622px;
        z-index: 10;
        display: flex;
        position: relative;
        margin-top: 10px;

        .content-left {
          margin-left: 80px !important;

          img {
            width: 575px;
            height: 622px;
          }

          .describe {
            font-family: PangMenZhengDao;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 21px;
            font-family: 'PangMenZhengDao';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 21px;
            letter-spacing: 0.880532px;
            color: rgba(255, 255, 255, 0.65);

            span:nth-child(1) {
              color: #fff;
              font-size: 24px;
              opacity: 1;
            }
          }

          .describe-one {
            position: relative;
            top: -540px;
            left: 42.0px;
          }

          .describe-two {
            position: relative;
            top: -365px;
            left: 14px;
          }
        }

        .content-right {
          img {
            position: relative;
            z-index: 1;
            margin-left: 105px;
            margin-top: 12px;
            width: 234.31px;
            height: 480.39px;
          }

          .tianchong {
            width: 210.21px;
            height: 454.61px;
            margin-top: -508px;
            margin-left: 117px;
          }

          .video-container {
            border-radius: 20px;
            border-radius: 25px;
            position: relative;
            z-index: 10;
            width: 212px;
            height: 448px;
            width: 210.21px;
            height: 454.61px;
            margin-top: -465px;
            margin-left: 117px;

            video {
              border-radius: 20px;
              border-radius: 25px;
              width: 210px;
              height: 448px;
              width: 210.21px;
              height: 454.61px;
            }
          }
        }
      }

      .content-bottom {
        float: right;
        margin-top: -100px;
        .bottom-title {
          text-align: right;

          img {
            width: 223px;
            height: 60px;
          }
        }

        .title-describe {
          text-align: right;
          margin-top: 5px;
          font-family: 'Hanson';
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          color: rgba(255, 255, 255, 0.65);
        }

        .split-line {
          margin-top: 16px;
          float: right;
          width: 268px;
          height: 0px;
          border-top: 1px solid #303030;
        }

        .chordMate-content {
          text-align: left;
          margin-top: 40px;
          width: 595px;
          height: 268px;
          font-family: 'PingFang SC';
          font-style: normal;
          font-weight: 300;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.2px;
          padding: 0;

          .content-first {
            color: rgba(255, 255, 255, 0.8);
            margin-bottom: 4px;
          }

          .content-paragraph {
            margin-top: 6px;

            .paragraph-title {
              color: #698DF5;
            }

            .paragraph-intro {
              color: rgba(255, 255, 255, 0.6);
            }
          }
        }

        .know-more {
          float: right;
          margin-top: 50px;
          width: 164px;
          height: 60px;
          background: #303030;
          text-align: center;
          border-radius: 40px;
          font-family: PingFang SC;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 60px;
          color: #5A85FD;
          cursor: pointer;
        }
      }
    }
  }
  
  .timbreTool-container{
    width: 100vw;
    min-width: 1200px;
    height: 1000px;
    overflow: hidden;
    position: relative;
    .allBackground{
      position: absolute;
      z-index: 0;
      img {
        height: 912px;
      }
    }

    .timbreTool-content{
      padding: 0 100px;
      z-index: 10 ;
      position: relative;
      width: 1200px;
      height: 1000px;
      background: url('../assets/introduce-image/timbreTool-background.png') no-repeat 570px -20px;
      background-size: 764px 642px;
      .timbreTool-top{
        // margin-top: 132px;
        // padding: 0 350px;
        padding: 132px 350px 0;
        img{
          width: 666px;
        }
      }
      .timbreTool-bottom{
        margin-top: 133px;
        color: rgba(255, 255, 255, 0.65);
        text-align: right;

        .bottom-title {
          img{
            width: 413px;
          }
        }

        .title-describe {
          margin-top: 5px;
          font-family: Hanson;
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 24px;
        }

        .split-line {
          margin-top: 16px;
          float: right;
          width: 268px;
          height: 0px;
          border-top: 1px solid #303030;
        }

        .function-description {
          margin-top: 40px;
          margin-left: 552px;
          text-align: left;
          width: 452px;
          font-family: "PingFang SC";
          font-size: 14px;
          font-style: normal;
          font-weight: 300;
          line-height: 22px;
          letter-spacing: 0.2px;
        }

        .knowMore {
          cursor: pointer;
          margin-top: 36px;
          margin-left: 836px;
          width: 164px;
          height: 60px;
          background: #303030;
          text-align: center;
          border-radius: 40px;
          font-family: PingFang SC;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 60px;
          color: #5A85FD;
        }
      }
    }
  }

  .introduce-partOne {
    width: 100vw;
    min-width: 1200px;
    height: 1000px;
    overflow: hidden;
    position: relative;

    .partOne-background {
      position: absolute;
      z-index: 0;

      img {
        height: 912px;
      }
    }

    .partOne-container {
      position: relative;
      z-index: 100;
      width: 1200px;
      padding: 0 100px;
      height: 1000px;

      .partOne-top {
        z-index: 10;
        display: flex;
        position: relative;
        margin-top: 88px;
      }

      .partOne-left {
        margin-top: 124px;
        margin-left: 132px;

        img {
          width: 523px;
          height: 474px;
        }

        .describe {
          font-family: PangMenZhengDao;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 21px;
          color: rgba(255, 255, 255, 0.65);

          span:nth-child(1) {
            color: #fff;
            font-size: 20px;
            margin-right: 5px;
          }

          span:nth-child(2) {
            font-size: 24px;
            color: rgba(255, 255, 255, 0.65);
          }
        }

        .describe-one {
          position: relative;
          top: -165px;
          left: -43px;
        }

        .describe-two {
          position: relative;
          top: -120px;
          left: -132px;
        }
      }

      .partOne-right {
        img {
          position: relative;
          z-index: 1;
          margin-left: 105px;
          margin-top: 42px;
          width: 234.31px;
          height: 480.39px;
        }

        .tianchong {
          width: 210.21px;
          height: 454.61px;
          margin-top: -508px;
          margin-left: 117px;
        }

        .video-container {
          border-radius: 20px;
          border-radius: 25px;
          position: relative;
          z-index: 10;
          width: 212px;
          height: 448px;
          width: 210.21px;
          height: 454.61px;
          margin-top: -465px;
          margin-left: 117px;

          video {
            border-radius: 20px;
            border-radius: 25px;
            width: 210px;
            height: 448px;
            width: 210.21px;
            height: 454.61px;
          }
        }
      }

      .partOne-bottom {
        margin-top: -84px;
        color: rgba(255, 255, 255, 0.65);
        text-align: right;

        .bottom-title {
          font-family: PangMenZhengDao;
          font-style: normal;
          font-weight: normal;
          font-size: 56px;
          line-height: 44px;
          color: rgba(255, 255, 255, 0.95);

          i {
            font-size: 56px;
          }
        }

        .title-describe {
          margin-top: 16px;
          font-family: Hanson;
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 24px;
        }

        .split-line {
          margin-top: 18px;
          float: right;
          width: 268px;
          height: 0px;
          border-top: 1px solid #303030;
        }

        .function-description {
          margin-top: 40px;
          margin-left: 552px;
          text-align: left;
          width: 448px;
          font-family: PingFang SC;
          font-style: normal;
          font-weight: 300;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.2px;

        }

        .knowMore {
          cursor: pointer;
          margin-top: 24px;
          margin-left: 836px;
          width: 164px;
          height: 60px;
          background: #303030;
          text-align: center;
          border-radius: 40px;
          font-family: PingFang SC;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 60px;
          color: #5A85FD;
        }
      }
    }
  }

  .introduce-partTwo {
    width: 100vw;
    min-width: 1200px;
    height: 1000px;
    overflow: hidden;
    position: relative;

    .partTwo-background {
      position: absolute;
      z-index: 0;

      img {
        height: 912px;
      }
    }

    .partTwo-container {
      padding: 0 100px;
      z-index: 10;
      position: relative;
      width: 1200px;
      height: 1000px;

      .partTwo-top {
        img {
          margin-top: 128px;
          margin-left: 256px;
          width: 744px;
          height: 448px;
        }

        .video-container {
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
          position: relative;
          z-index: 10;
          width: 742px;
          height: 417px;
          overflow: hidden;
          margin-top: -420px;
          margin-left: 257px;

          video {
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
            width: 742px;
            height: 415px;
          }
        }
      }

      .partTwo-bottom {
        margin-top: 42px;
        color: rgba(255, 255, 255, 0.65);
        text-align: right;

        .bottom-title {
          font-family: PangMenZhengDao;
          font-style: normal;
          font-weight: normal;
          font-size: 56px;
          line-height: 44px;
          color: rgba(255, 255, 255, 0.95);

          i {
            font-size: 56px;
          }
        }

        .title-describe {
          margin-top: 16px;
          font-family: Hanson;
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 24px;
        }

        .split-line {
          margin-top: 18px;
          float: right;
          width: 268px;
          height: 0px;
          border-top: 1px solid #303030;
        }

        .function-description {
          margin-top: 40px;
          margin-left: 552px;
          text-align: left;
          width: 448px;
          font-family: PingFang SC;
          font-style: normal;
          font-weight: 300;
          font-size: 14px;
          line-height: 20px;

          span {
            font-weight: normal;
          }

          letter-spacing: 0.2px;
        }

        .knowMore {
          cursor: pointer;
          margin-top: 24px;
          margin-left: 836px;
          width: 164px;
          height: 60px;
          background: #303030;
          text-align: center;
          border-radius: 40px;
          font-family: PingFang SC;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 60px;
          color: #5A85FD;
        }
      }
    }

  }
}
</style>
