<template>
    <div class="home-overLay" >
        <div class="overLay-container">
            <div class="close-overlay" @click="closeOverlay">×</div>
            <div class="container-title">
                <img src="../assets/introduce-image/chordMate-title.png" />
            </div>
            <div class="ercode-container">
                <div class="ios-container">
                    <div class="ios-top">
                        <img src="../assets/home-image/ios.png" />
                        <img src="../assets/home-image/ercode.png" />
                    </div>
                    <div class="ios-bottom">
                        <div class="bottom-left">
                            <div>和弦派IOS版</div>
                            <div>移动端</div>
                        </div>
                        <div class="bottom-right" @click="jumpAppStore">
                            跳转应用商店<i class="iconfont icon-lejiegengduo"></i>
                        </div>
                    </div>
                </div>
                <div class="android-container">
                    <div class="android-top">
                        <img src="../assets/home-image/android.png" />
                        <img src="../assets/home-image/ercode.png" />
                    </div>
                    <div class="android-bottom">
                        <div class="bottom-left">
                            <div>和弦派Android版</div>
                            <div>移动端</div>
                        </div>
                        <div class="bottom-right">
                            扫码下载
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default ({
    name: 'QrCode',
    data() {
        return {
            scrollTop: 0
        }
    },
    mounted() {
        window.addEventListener("scroll", this.getScrollTop, true)
    },
    methods: {
        getScrollTop() {
            this.scrollTop = document.documentElement.scrollTop;
        },
        closeOverlay() {
            this.$emit('closeOverlay')
        },
        jumpAppStore() {
            window.open("https://apps.apple.com/cn/app/chordmate/id1660541229")
        },
    }

})
</script>
<style scoped lang="less">

.home-overLay {
    z-index: 1000;
    width: 100vw;
    height: 100vh;
    min-width: 1200px;
    min-height: 900px;
    background: rgba(40, 40, 40, 0.92);
    position: fixed;
    left: 0;
    top: 0;

    .overLay-container {
        width: 950px;
        height: 540px;
        background: #141414;
        box-shadow: 0px 21px 20px -4px rgba(0, 0, 0, 0.37);
        border-radius: 10px;
        position: absolute;
        margin: auto;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        .close-overlay {
            cursor: pointer;
            color: #fff;
            opacity: 0.8;
            font-size: 28px;
            position: absolute;
            top: -5px;
            right: 10px;
        }

        .container-title {
            margin: 52px 381.5px 0;

            img {
                width: 187px;
            }
        }

        .ercode-container {
            margin-top: 61px;
            display: flex;

            .ios-container {
                margin-left: 101px;
                width: 328px;
                height: 298px;
                background: #202020;

                .ios-top {
                    margin-top: 47px;

                    img:nth-child(1) {
                        width: 113px;
                        margin-left: 37px;
                    }

                    img:nth-child(2) {
                        width: 122px;
                        margin-left: 27.5px;
                    }
                }

                .ios-bottom {
                    width: 328px;
                    height: 85px;
                    background: #323232;
                    margin-top: -24px;
                    position: relative;
                    z-index: 100;
                    box-shadow: 0px -10px 12px rgba(0, 0, 0, 0.25);
                    padding: 20px 15px 21px 24px;
                    display: flex;
                    justify-content: space-between;

                    .bottom-left {
                        div:nth-child(1) {
                            font-family: 'PingFang SC';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 19px;
                            line-height: 20px;
                            letter-spacing: 0.2px;
                            color: #FFFFFF;
                        }

                        div:nth-child(2) {
                            margin-top: 4px;
                            font-family: 'PingFang SC';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 19px;
                            line-height: 20px;
                            letter-spacing: 0.2px;
                            color: #FFFFFF;
                        }
                    }

                    .bottom-right {
                        cursor: pointer;
                        font-family: 'PingFang SC';
                        font-style: normal;
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 24px;
                        display: flex;
                        align-items: center;
                        text-align: right;
                        color: #0DC5B4;
                    }
                }
            }

            .android-container {
                margin-left: 101px;
                width: 328px;
                height: 298px;
                background: #202020;

                .android-top {
                    margin-top: 47px;

                    img:nth-child(1) {
                        width: 113px;
                        margin-left: 37px;
                    }

                    img:nth-child(2) {
                        width: 122px;
                        margin-left: 27.5px;
                    }
                }

                .android-bottom {
                    width: 328px;
                    height: 85px;
                    background: #323232;
                    margin-top: -24px;
                    position: relative;
                    z-index: 100;
                    box-shadow: 0px -10px 12px rgba(0, 0, 0, 0.25);
                    padding: 20px 15px 21px 24px;
                    display: flex;
                    justify-content: space-between;

                    .bottom-left {
                        div:nth-child(1) {
                            font-family: 'PingFang SC';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 19px;
                            line-height: 20px;
                            letter-spacing: 0.2px;
                            color: #FFFFFF;
                        }

                        div:nth-child(2) {
                            margin-top: 4px;
                            font-family: 'PingFang SC';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 19px;
                            line-height: 20px;
                            letter-spacing: 0.2px;
                            color: #FFFFFF;
                        }
                    }

                    .bottom-right {
                        font-family: 'PingFang SC';
                        font-style: normal;
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 24px;
                        display: flex;
                        align-items: center;
                        text-align: right;
                        color: #0DC5B4;
                    }
                }
            }
        }
    }
}
</style>