<template>
  <div class="about">
    <div class="about-introduce">
      <div class="about-introduce-text">
        <div class="compony-title">企业简介</div>
        <div class="compony-intro">Enterprise brief introduction</div>
        <div class="compony-describe">
          北京灵动音科技有限公司(DeepMusic)是一家致力于人工智能音乐的公司，成立于2018年2月。创始团队来自清华大学，核心技术来自清华大学计算机系智能技术与系统国家重点实验室，已有5年技术积累，清华大学知识产权技术入股公司。<br/>公司致力于运用AI技术从作词、作曲、编曲、演唱、混音等方面全方位降低音乐创作及制作门槛，为音乐行业提供新的产品体验，提升效率。2018年2月获得华控基石基金、李健个人的数百万元天使轮投资，2020年8月获得TME领投的数千万元A轮融资。
        </div>
      </div>
    </div>
    <div class="about-growUp">
      <div class="team-title">成长之路</div>
      <div class="title-intro">course of growth</div>
      <div class="growUp-content">
        <div class="every-content" v-for="(item,index) in growUpArr" :key="index">
           <!-- :style="'background:' + item.color" -->
          <div class="content-describe" v-show="item.showFlag">{{item.describe}}</div>
          <div class="content-content">
            <div class="content-hoverUrl"  v-show="item.showFlag">
              <img :src="item.hoverUrl"/>
            </div>
            <div class="content-imgUrl">
              <img v-show="!item.showFlag" :src="item.imgUrl" @mouseover="changeFlag(index)" @mouseout="changeFlag2(index)"/>
              <img v-show="item.showFlag" :src="item.imgUrl2" @mouseover="changeFlag(index)" @mouseout="changeFlag2(index)"/>
            </div>
            <div class="content-time" :style="item.showFlag ? 'color: rgba(255, 255, 255, 0.85);' : 'color: rgba(255, 255, 255, 0.45);'">{{item.time}}</div>
            <div class="content-intro">{{item.intro}}</div>
          </div>
        </div>
      </div>
      <div class="time-line"></div>
    </div>
    <div class="team-introduce">
      <div class="team-title">团队介绍</div>
      <div class="title-intro">team interaction</div>
      <div class="team-intro">
        <div class="intro-content" v-for="(item, index) in teamArr" :key="index">
          <img class="background-image" :src="item.img"/>
          <img class="background-checkedImage" :src="item.checkedImg"/>
          <div class="text-content">
            <div class="text-title">{{item.title}}</div>
            <div class="text-intro" v-for="(item1, index1) in item.intro" :class="'text-intro' + index1" :key="index1"><p>{{item1}}</p></div>
            <div class="iconfont"><i class="iconfont" :class="item.iconfont"></i></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
  },
  data: () => ({
    teamArr: [
      {
        title: "科研团队", 
        intro: ["技术团队专注于使用人工智能等一切技术手段提升音乐的自动化知识提取、内容生产和实时交互能力，以降低音乐创作的使用门槛，并提供音乐类应用的技术想象力。目前，团队在音乐信息提取、流行音乐词曲和编曲创作、音乐工程渲染、音频引擎框架等方面均有成熟和先进的解决方案。"],
        iconfont: "icon-keyan",
        img: require("../assets/about-image/1.png"),
        checkedImg: require("../assets/about-image/lan1.png")
      },
      {
        title: "产品团队", 
        intro: ["产品团队在音乐和科技交叉的领域不断探索，旨在为用户提供更加智能和人性化的音乐体验，帮助用户更好的欣赏音乐、使用音乐、创作音乐、玩音乐。","目前团队专注在提升音乐UGC、PGC的内容质量和生产效率的产品方向上。在AI DAW、音乐可视化、音乐游戏等方面有较多积淀。"],
        iconfont: "icon-chanpin",
        img: require("../assets/about-image/2.png"),
        checkedImg: require("../assets/about-image/lan2.png")
      },
      {
        title: "音乐团队", 
        intro: ["音乐制作团队一方面在不断积累丰富的高质量音乐内容，包括音色、编曲、混音，另一方面在尝试各种音乐制作新方法，为AI音乐制作等技术研究提供专业支持。","音乐标注团队一直专注在存量歌曲的音乐信息标注工作上，包括速度、节拍、段落、和弦、旋律、歌词等，为音乐信息检索(MIR)技术研究提供数据支持。"],
        iconfont: "icon-yinle",
        img: require("../assets/about-image/3.png"),
        checkedImg: require("../assets/about-image/lan3.png")
      }
      
    ],
    growUpArr: [
      {
        color:  "#478799",
        showFlag: false,
        time: '2018/03',
        intro: "公司成立",
        imgUrl: require("../assets/about-image/timeLine-one.png"),
        imgUrl2: require("../assets/about-image/timeLine-one-backups.png"),
        hoverUrl: require("../assets/about-image/timeLine-one-hover.png"),
        describe: "灵动音科技创始团队来自清华大学，核心技术孵化自清华大学计算机系智能技术与系统国家重点实验室。2018年获得华控基石基金及歌手李健总计数百万元天使轮投资。"
      },
      {
        color:  "#792339",
        showFlag: false,
        time: '2018-2020',
        intro: "数据积累与技术探索",
        imgUrl: require("../assets/about-image/timeLine-two.png"),
        imgUrl2: require("../assets/about-image/timeLine-two-backups.png"),
        hoverUrl: require("../assets/about-image/timeLine-two-hover.png"),
        describe: "公司创立后，独创性地标注了数千首基于中日韩热门曲库的音乐数据集，用于人工智能作曲的训练。同时公司研发出一系列的具有人性化的人工智能编曲、混音等技术。"
      },
      {
        color:  "#234552",
        showFlag: false,
        time: '2020-2021',
        intro: "技术接口多场景的落地实践",
        imgUrl: require("../assets/about-image/timeLine-three.png"),
        imgUrl2: require("../assets/about-image/timeLine-three-backups.png"),
        hoverUrl: require("../assets/about-image/timeLine-three-hover.png"),
        describe: "2020年与中国和日本最大在线K歌平台合作，推出了“AI换曲风”玩法，获数亿次使用。与此同时通过交互式作曲玩法，将AI作曲技术应用在手机、汽车等终端的创意娱乐场景中。"
      },
      {
        color:  "#256795",
        showFlag: false,
        time: '2021-',
        intro: "个人产品探索",
        imgUrl: require("../assets/about-image/timeLine-four.png"),
        imgUrl2: require("../assets/about-image/timeLine-four-backups.png"),
        hoverUrl: require("../assets/about-image/timeLine-four-hover.png"),
        describe: "将AI词曲编等技术应用于C端产品，降低创作和制作门槛。将难学习、编辑的音乐内容变为选择制。让更多非音乐专业的朋友也能释放自己的音乐想法。"
      }
    ]
  }),
  methods: {
    changeFlag(index){
      this.growUpArr[index]['showFlag'] = true;
    },
    changeFlag2(index){
      this.growUpArr[index]['showFlag'] = false;
    }
  }
};
</script>

<style scoped lang="less">
@media screen and (min-width: 1200px){
  .about{
    margin-left: calc((100vw - 1200px) / 2);
  }
  .about-introduce{
    margin-left: calc((100vw - 1200px) / (-2));
  }
  .about-introduce-text{
    margin-left: calc((100vw - 1200px) / 2);
  }
}
.about{
  width:1200px;
  height: 2605px;
  .about-introduce{
    background-position: center !important;
    background:url('../assets/about-image/关于我们背景.png') no-repeat;
    width: 100vw;
    min-width: 1200px;
    height: 900px;
    z-index: 0;
    border: 1px solid #141414;
    margin-top: -88px;
    .about-introduce-text{
      width: 1200px;
      height: 900px;
      padding: 0 100px 0 100px;
      color: #fff;
      text-align: right;
      .compony-title{
        margin-top: 328px;
        font-family: PangMenZhengDao;
        font-style: normal;
        font-weight: normal;
        font-size: 56px;
        line-height: 44px;
        color: rgba(255, 255, 255, 0.95);
        opacity: 0.95;
      }
      .compony-intro{
        margin-top: 16px;
        font-family: Hanson;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        text-transform: capitalize;
        color: rgba(255, 255, 255, 0.65);
      }
      .compony-describe{
        float: right;
        text-align: left;
        width: 440px;
        margin-top: 24px;
        font-family: PingFang SC;
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 21px;
        color: #FFFFFF;
        opacity: 0.65;
      }
    }
  }
  .about-growUp{
    position: relative;
    padding: 0 100px;
    margin-top: 90px;
    height: 536px;
    .time-line{
      z-index: 0;
      position: absolute;
      bottom: 110px;
      width: 1040px;
      height: 2px;
      background: linear-gradient(to right, rgba(255, 255, 255, 0) ,rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
    }
    .team-title{
      font-family: PangMenZhengDao;
      font-style: normal;
      font-weight: normal;
      font-size: 56px;
      line-height: 44px;
      color: rgba(255, 255, 255, 0.95);
    }
    .title-intro{
      margin-top: 16px;
      font-family: Hanson;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      text-transform: capitalize;
      color: rgba(255, 255, 255, 0.65);
    }
    .growUp-content{
      z-index: 10;
      position: absolute;
      bottom: 0;
      display: flex;
      .every-content{
        width: 264px;
        text-align: center;
        .content-describe{
          text-align: left;
          background: #202020;
          position: absolute;
          width: 264px;
          padding: 56px 24px;
          bottom: 152px;
          font-family: PingFang SC;
          font-style: normal;
          font-weight: 300;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.12px;
          color: rgba(255, 255, 255, 0.65);
        }
        .content-hoverUrl{
          width: 264px;
          text-align: center;
          position: absolute;
          bottom: 49px;
        }
        .content-imgUrl{
          width: 264px;
          text-align: center;
          position: absolute;
          bottom: 80px;
          img{
            border-radius: 130px;
          }
        }
        .content-time{
          position: absolute;
          bottom: 29px;
          width: 264px;
          text-align: center;
          font-family: Hanson;
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.2px;
        }
        .content-intro{
          position: absolute;
          bottom: 0;
          width: 264px;
          text-align: center;
          font-family: PangMenZhengDao;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 21px;
          color: rgba(90, 133, 253, 0.95);
        }
      }
      
    }
    .every-content:nth-child(1){
      margin-left: 42px;
    }
    .every-content:nth-child(2){
      margin-left: -34px;
    }
    .every-content:nth-child(3){
      margin-left: -32px;
    }
    .every-content:nth-child(4){
      margin-left: -32px;
    }
  }
  .team-introduce{
    position: relative;
    height: 594px;
    margin-top: 362px;
    color: #fff;
    padding: 0 100px;
    .team-title{
      font-family: PangMenZhengDao;
      font-style: normal;
      font-weight: normal;
      font-size: 56px;
      line-height: 44px;
      color: rgba(255, 255, 255, 0.95);
    }
    .title-intro{
      margin-top: 16px;
      font-family: Hanson;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      text-transform: capitalize;
      color: rgba(255, 255, 255, 0.65);
    }
    .team-intro{
      margin-top: 90px;
      display: flex;
      .intro-content{
        margin-right: 30px;
        .background-checkedImage{
          display: none;
        }
        .text-content{
          margin-top: -432px;
          padding: 0 32px;
          .text-title{
            margin-top: 80px;
            font-family: PangMenZhengDao;
            font-style: normal;
            font-weight: normal;
            font-size: 24px;
            line-height: 20px;
            letter-spacing: 0.2px;
            color: rgba(255, 255, 255, 0.85);
          }
          .text-intro{
            margin-top: 24px;
            font-family: PingFang SC;
            font-style: normal;
            font-weight: 300;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.2px;
            color: rgba(255, 255, 255, 0.65);
          }
          .text-intro1{
            margin-top: -16px;
          }
          .iconfont{
            color: rgba(255, 255, 255, 0.65);
            margin-top: 56px;
            i{
              font-size: 26px;
            }
          }
        }
      }
      .intro-content:nth-child(2){
        .text-content {
          .iconfont{
          }
        }
      }
      .intro-content:last-child{
        .text-content {
          .iconfont{
            margin-top: 36px;
          }
        }
      }
      .intro-content:hover{
        cursor: default;
        .background-image{
          display: none;
        }
        .background-checkedImage{
          display: block;
        }
        i{
          color: rgba(255, 255, 255, 0.45);
        }
      }
      .intro-content:nth-child(2):hover{
        .text-content{
          margin-top: -431px;
        }
      }
      .intro-content:nth-child(3):hover{
        .text-content{
          margin-top: -431px;
        }
      }
    }
  }
}
</style>
