<template>
  <div class="contact">
    <div class="contact-background">
      <img src="../assets/home-image/background.png"/>
    </div>
    <div class="contact-container">
      <div class="contact-contactUs">
        <div class="contactUs-title">联系我们</div>
        <div class="contactUs-describe">Contact Us</div>
        <div class="contactUs-bottom">
          <div class="bottom-left">
            <img src="../assets/contact-image/location.png"/>
          </div>
          <div class="bottom-right">
            <div class="rightOdd"><i class="iconfont icon-dianhua"></i>电话</div>
            <div class="rightEven tel1">商务合作：010-53930039</div>
            <div class="rightEven tel2">招聘咨询：010-53857716</div>
            <div class="rightOdd"><i class="iconfont icon-youxiang"></i>邮箱</div>
            <div class="rightEven">bd@lazycomposer.com</div>
            <div class="rightOdd"><i class="iconfont icon-dizhi"></i>地址</div>
            <div class="rightEven">北京市海淀区西小口路66号中关村东升科技园北领地B-6楼C座2层C202A</div>
          </div>
        </div>
      </div>
      <div class="contact-joinUs" v-if="false">
        <div class="joinUs-title">加入我们</div>
        <div class="joinUs-describe">Join Us</div>
        <div class="joinUs-bottom">
          <div class="joinUs-left">
            <span 
              :style="positionIndex == 0 ? '' : 'cursor: pointer;'" 
              @click="changePositionIndex('reduce')">
              <i class="iconfont icon-zhaopinzuo"></i>
            </span>
            <span 
              :style="positionIndex == positionArr.length - 1 ? '' : 'cursor: pointer;'" 
              @click="changePositionIndex('add')">
              <i class="iconfont icon-zhaopinyou"></i>
            </span>
            <div class="left-top" id="left-top">
              <!-- @click="changePositionIndex(index)"  -->
              <div
                @click="changePositionIndex(index)"
                class="position-name" :style="index == positionIndex ? 'background: #303030;' : ''" 
                v-for="(item, index) in positionArr" :key="'infor1' +index">
                {{item.positionName}}
              </div>
            </div>
            <div class="left-bottom" id="left-bottom">
              <div class="position-describe"  v-for="(item, index) in positionArr" :key="'infor2' + index">
                <div class="positionTitle bottom-positionDes">职位描述</div>
                <div class="positionTitle-dec bottom-positionDes-dec" v-for="(item1, index1) in item.positionDes" :key="'desc1' + index1">
                  {{item1}}
                </div>
                <div class="positionTitle bottom-positionRequir">职位要求</div>
                <div class="positionTitle-dec bottom-positionRequir-dec" v-for="(item2, index2) in item.positionRequir" :key="'desc2' + index2">
                  {{item2}}
                </div>
                <div class="positionTitle bottom-positionPriority" v-if="'positionPriority' in positionArr[index]">附加条件</div>
                <div class="positionTitle-dec bottom-positionPriority-dec" v-for="(item3, index3) in item.positionPriority" :key="'desc3' + index3">
                  {{item3}}
                </div>
              </div>
              
            </div>
            <ul>
              <li v-for="(item, index) in positionArr" :key="'infor3' +index" :class="positionIndex == index ? 'choosed' : ''"></li>
            </ul>
          </div>
          <div class="joinUs-right">
            <img src="../assets/contact-image/partner.png" />
          </div>
        </div>
        
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  data: () => ({
    positionIndex: 0,
    positionArr: [
      {
        positionName: "IOS开发工程师",
        positionDes: [
          "1. 负责音乐交互、创作、教育和娱乐相关业务 iOS App 的开发工作，参与产品需求把控和技术方案的设计工作。",
          "2. 参与人工智能音乐交互技术的 iOS/Mac 端应用的设计和开发工作。"
        ],
        positionRequir: [
          "1. 计算机相关专业本科及以上学历，两年以上 iOS 开发经验。",
          "2. 熟练掌握 Xcode 开发环境及调试工具，熟悉性能分析工具的使用。",
          "3. 精通 Swift 和 Objective-C。了解各版本 SDK 的新特性以及版本差异。",
          "4. 精通 iOS UI 框架，灵活使用多分辨率适配的各种方法。精通 iPad、iPhone 各类 iOS 设备上 App 的设计与开发。",
          "5. 熟悉 iOS 流行的开源组件及框架。",
          "6. 精通 MVVM、MVP 架构设计，组件化实际项目开发。",
          "7. 具备良好的编码规范和独立学习能力。",
          "8. 具备优秀的沟通能力，能够在小型产品开发团队中独当一面，协同产品进行快速开发。"
        ],
        positionPriority: [
          "1. 有音乐实践经历（如演奏、演唱、指挥、作曲、编曲等）优先。",
          "2. 熟悉 AU 音频引擎模块（CoreAudio, AVFoundation, AudioEngine 相关）优先。",
          "3. 熟悉 Python、C++、HTML5、OpenGL 及 DSP 音频算法优先。"
        ]
      },
      {
        positionName: "基础音乐信息/流行音乐标注",
        positionDes: [
          "1. 使用内部工具对曲库内歌曲进行测速及段落标注。",
          "2. 对照音频将已有midi、歌词文件进行精细标注或制作新的midi、歌词文件。"
        ],
        positionRequir: [
          "1. 有一定的乐理基础，熟悉流行音乐的结构。",
          "2. 熟悉Logic，Pro Tools，Cubase等DAW软件中任意一种，有编曲、录音、混音等经验者优先。",
          "3. 逻辑清晰，责任心强，工作热情有耐心，踏实认真自信，具有良好的沟通能力。"
        ]
      },
      {
        positionName: "AI音乐算法工程师",
        positionDes: [
          "参与 AI 作曲、AI 编曲等音乐创作相关算法的研发、应用与优化。"
        ],
        positionRequir: [
          "1. 熟练掌握 Python 编程技能，有扎实的编程基础和良好的编程风格。",
          "2. 熟悉至少一种主流深度学习框架（Tensorflow/PyTorch)。",
          "3. 熟悉深度学习算法 CNN/RNN/Transformer/GAN等。具备独立快速阅读整理文献和复现模型的能力；具备自己设计、搭建、修改、优化网络结构和损失函数的能力；具备独立的调参能力，熟悉数据不平衡等实际工程问题的处理方法。",
          "4. 对音乐生成、自然语言处理等相关领域有一定了解。",
          "5. 具有独立解决问题的能力，良好的团队合作意识和沟通能力。",
          "6. 对 AI 音乐领域感兴趣。"
        ]
      },
      // 高级python开发工程师
      // {
      //   positionName: "高级python开发工程师",
      //   positionDes: [
      //     "1. 人工智能音乐创作系统的支撑平台和相关基础设施的开发工作。",
      //     "2. 人工智能音乐创作系统的相关数据库设计与开发工作。",
      //     "3. 参与小型产品研发团队并承担主要后端技术开发职责。"
      //   ],
      //   positionRequir: [
      //     "1. 计算机相关专业本科及以上学历。",
      //     "2. 熟练掌握 Python 语言的开发，并掌握至少一种基于 Python 的后端开发技术。",
      //     "3. 熟练掌握数据库技术和设计方法，具备基于 MySQL 的数据库开发能力以及相关工具的使用经验。",
      //     "4. 具备良好的代码阅读能力、需求理解能力和敏捷开发能力，能够快速理解团队提供的 API 接口，并根据项目需求给出技术方案。",
      //     "5. 具备良好的沟通能力，能够在小型产品团队中担任后端技术开发的主要职责，协同产品进行快速开发。",
      //     "6. 对AI音乐领域感兴趣，自学能力、小型团队协作能力强。"

      //   ],
      //   positionPriority: [
      //     "1. 熟悉 DSP 音频算法和 C++ 语言开发者优先考虑。",
      //     "2. 熟悉 VST/AU/AAX 等音频插件和音频引擎等技术者优先考虑。",
      //     "3. 有音乐实践经历（如演奏、演唱、指挥、作曲、编曲等）优先考虑。",
      //     "4. 了解 k8s、docker 容器技术，了解腾讯云等国内主流云平台架构和操作流程。",
      //     "5. 熟悉 Qt 框架者优先考虑。",
      //     "6. 会前端开发优先考虑。"
      //   ]
      // },
      // 音频算法工程师
      {
        positionName: "音频算法工程师",
        positionDes: [
          "参与 MIR 等音频相关算法的研发、应用与优化。"
        ],
        positionRequir: [
          "1. 熟练掌握 Python 编程技能，有扎实的编程基础和良好的编程风格。",
          "2. 熟悉至少一种主流深度学习框架（Tensorflow/PyTorch)。",
          "3.熟悉深度学习算法 CNN/RNN/Transformer/GAN 等。具备独立快速阅读整理文献和复现模型的能力；具备自己设计、搭建、修改、优化网络结构和损失函数的能力；具备独立的调参能力，熟悉数据不平衡等实际工程问题的处理方法。",
          "4. 对 MIR、DSP 等音频相关领域有一定了解； 了解 RubberBand 等音频 拉伸算法、World或其他声码器、采样器/合成器技术、语音合成等技术者优先考虑。",
          "5. 具有独立解决问题的能力，良好的团队合作意识和沟通能力。",
          "6. 对 AI 音乐领域感兴趣。"
        ]
      },
      // 安全运维工程师
      // {
      //   positionName: "安全运维工程师",
      //   positionDes: [
      //     "1. 统一管理服务器、数据库、缓存、负载均衡等基础架构云服务及资源。",
      //     "2. 负责全部云端业务的运行维护及应急响应，及时发现问题，提前排查隐患。",
      //     "3. 参与公司网络安全架构的建设，参与制定运维体系流程和规范，根据业务需求制定解决方案。",
      //     "4. 参与构建日志收集、监控报警的自动化体系。",
      //     "5. 解决日常安全问题，在出现网络攻击或安全事件时进行紧急响应、恢复系统及调查取证。"
      //   ],
      //   positionRequir: [
      //     "1. 本科及以上学历，计算机科学与技术或软件工程专业毕业，3年及以上运维开发或运维平台建设经验。",
      //     "2. 能够使用 python、 shell熟练编写自动化脚本，迅速完成日常运维需求。",
      //     "3. 熟练使用 Docker 和 Kubernetes。",
      //     "4. 熟悉 Linux 系统、熟悉常用软件 nginx、tomcat、mysql、redis、MongoDB、ES、Kafka 的运维和调优。",
      //     "5. 熟悉 git 等 CI/CD 相关工具与服务。",
      //     "6. 有快速止损，快速定位、分析、解决、反馈问题的能力和意识，具备良好的沟通能力和团队协作精神，良好的敬业精神和责任心。",
      //     "7. 有腾讯云等云平台使用经验者优先。"
      //   ]
      // },
      // Android开发工程师
      {
        positionName: "Android开发工程师",
        positionDes: [
          "1. 负责音乐交互、创作、教育和娱乐相关业务 Android App 的开发工作，参与产品需求把控和技术方案的设计工作。",
          "2. 参与人工智能音乐交互技术的 Android端应用的设计和开发工作。"
        ],
        positionRequir: [
          "1. 计算机相关专业本科及以上学历，两年以上 Android 开发经验。",
          "2. 精通 Java 语言。深入理解 Android SDK，能够实现复杂的控件逻辑。",
          "3. 熟练掌握 Android 平台下的高性能编程和性能调优。",
          "4. 具备良好的学习和沟通能力，能够在小型产品团队中担任技术开发的主要职责，协同产品进行快速开发。"
        ],
        positionPriority: [
          "1. 有音乐实践经历（如演奏、演唱、指挥、作曲、编曲等）优先。",
          "2. 有音频引擎（如 JUCE 等）或 VST 软件音源/音乐效果器的开发经验。",
          "3. 熟悉 Python、C++、HTML5、OpenGL 及 DSP 音频算法优先。"
        ]
      },
    ]
  }),
  methods: {
    changePositionIndex(tip) {
      if(tip == 'reduce') {
        if(this.positionIndex == 0){
          return;
        }
        this.positionIndex --;
      } else if(tip == 'add') {
        if(this.positionIndex == this.positionArr.length - 1) {
          return;
        }
        this.positionIndex ++;
      }else {
        this.positionIndex = tip
      }
      document.getElementById("left-top").style.transition ="margin-left 0.5s";
      if(this.positionIndex == this.positionArr.length - 1) {
        document.getElementById("left-top").style.marginLeft = - (this.positionIndex - 1) * 293 + 'px';
      }else if(this.positionIndex % 2 == 0){
        document.getElementById("left-top").style.marginLeft = - this.positionIndex * 293 + 'px';
      } else {
        document.getElementById("left-top").style.marginLeft = - (this.positionIndex - 1) * 293 + 'px';
      }
      document.getElementById("left-bottom").style.transition ="margin-left 0.5s";
      document.getElementById("left-bottom").style.marginLeft = - this.positionIndex * 588 + 'px';
    }
  }
}
</script>

<style scoped lang="less">
@media screen and (min-width: 1200px){
  .contact{
    margin-left: calc((100vw - 1200px) / 2);
  }
}
.contact{
  width:1200px;
  color: #fff;
  overflow: hidden;
  position: relative;
  min-height: 550px;
  .contact-background{
    position: absolute;
    z-index: 0;
    margin-left: 110px;
    margin-top: 127px;
    img{
      width: 780px;
      height: 897px;
      margin-left: 100px;
      margin-top: 130px;
      transform: rotate(30deg);
    }
  }
  .contact-container{
    position: absolute;
    z-index: 100;
    padding: 0 100px 0 100px;
    .contact-contactUs{
      .contactUs-title{
        margin-top: 82px;
        font-family: PangMenZhengDao;
        font-style: normal;
        font-weight: normal;
        font-size: 56px;
        line-height: 44px;
        color: rgba(255, 255, 255, 0.95);
        opacity: 0.95;
      }
      .contactUs-describe{
        margin-top: 16px;
        font-family: Hanson;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        color: rgba(255, 255, 255, 0.65);
        opacity: 0.95;
      }
      .contactUs-bottom{
        margin-top: 82px;
        display: flex;
        .bottom-left{
          margin-right:32px;
          img{
            width: 587px;
            height: 300px;
          }
        }
        .bottom-right{
          width: 286px;
          margin-top:  -4px;
          .rightOdd{
            i{
              color: rgba(90, 133, 253, 0.65);
              margin-right: 20px;
              font-size: 20px;
            }
            margin-top: 24px;
            font-family: PingFang SC;
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 28px;
            font-feature-settings: 'salt' on, 'liga' off;
            color: rgba(255, 255, 255, 0.65);
          }
          .rightEven{
            margin-top: 10px;
            font-family: PingFang SC;
            font-style: normal;
            font-weight: 300;
            font-size: 14px;
            line-height: 21px;
            color: rgba(255, 255, 255, 0.65);
          }
          .tel1{
             margin-top: 10px;
          }
          .tel2{
            margin-top: 5px;
          }
        }
      }
    }
    .contact-joinUs{
      .joinUs-title{
        text-align: right;
        margin-top: 170px;
        font-family: PangMenZhengDao;
        font-style: normal;
        font-weight: normal;
        font-size: 56px;
        line-height: 44px;
        color: rgba(255, 255, 255, 0.95);
        opacity: 0.95;
      }
      .joinUs-describe{
        text-align: right;
        margin-top: 16px;
        font-family: Hanson;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        color: rgba(255, 255, 255, 0.65);
        opacity: 0.95;
      }
      .joinUs-bottom{
        margin-top: 80px;
        display: flex;
        .joinUs-left{
          position: relative;
          overflow: hidden;
          margin-right: 40px;
          width: 588px;
          height: 304px;
          border: 1px solid #303030;
          span{
            position: absolute;
            color: rgba(255, 255, 255, 0.45);
            i{
              font-size: 30px;
              line-height: 88px;
            }
          }
          span:nth-child(1){
            margin-left: 32px;
            margin-top: 0px;
          }
          span:nth-child(2){
            margin-left: 525px;
          }
          .left-top{
            width: 2051px;
            .position-name{
              cursor: pointer;
              display: inline-block;
              width: 293px;
              height: 88px;
              text-align: center;
              font-family: PingFang SC;
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              line-height: 88px;
              color: rgba(255, 255, 255, 0.85);
            }
          }
          .left-bottom{
            width: 4116px;
            height: 164px;
            margin-top: 26px;
            .position-describe{
              display: inline-block;
              width: 588px;
              height: 164px;
              overflow-y: scroll;
              padding:0 32px;
              .positionTitle{
                margin-top:  14px;
                font-family: PingFang SC;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 21px;
                color: rgba(255, 255, 255, 0.65);
              }
              .bottom-positionDes{
                margin-top:  0;
              }
              .positionTitle-dec{
                font-family: PingFang SC;
                font-style: normal;
                font-weight: 300;
                font-size: 14px;
                line-height: 21px;
                color: rgba(255, 255, 255, 0.65);
              }
            }
          }
          ul{
            text-align: center;
            margin-top: 5px;
            margin-top: 10px;
            li{
              border-radius: 10px;
              margin-right: 5px;
              list-style: none;
              display: inline-block;
              width: 5px;
              height:6px;
              background: #505050;
            }
            .choosed{
              transition: height 0.5s;
              height: 8px;
              background: #fff;
            }
          }
        }
        .joinUs-right{
          img{
            width:  376px;
            height: 304px;
          }
        }
      }
    }
  }
}
</style>
