import Vue from 'vue'
import VueRouter from 'vue-router'
import home from '../components/home.vue'
import introduce from '../components/introduce.vue'
import technology from '../components/technology.vue'
import about from '../components/about.vue'
import contact from '../components/contact.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'home',
    component: home
  },
  {
    path: '/introduce',
    name: 'introduce',
    component: introduce
  },
  {
    path: '/technology',
    name: 'technology',
    component: technology
  },
  {
    path: '/about',
    name: 'about',
    component: about
  },
  {
    path: '/contact',
    name: 'contact',
    component: contact
  },
  {
    path: '*',
    redirect: '/home'
  }
]



const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash
      }
    }
  }

})

export default router
