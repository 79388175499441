<template>
  <div class="home">
    <div class="home-introduce">
      <div class="home-introduce-floatLayer">
        <div class="lazycomposer-company">
          <div class="company-name1">DEEP MUSIC</div>
          <div class="company-name2">DEEP MUSIC</div>
        </div>
        <div class="lazycomposer-introduce">
          致力于通过AI词曲编录混技术，全方位降低音乐创作制作门槛并提升效率，为音乐行业提供新的产品体验和解决方案
        </div>
      </div>
    </div>
    <div class="home-music-AI">
      <div class="AI-left">
        <div class="left-part1">音乐+AI的探索</div>
        <div class="left-part2">Music +AI exploration</div>
        <div class="left-part3">我们是谁</div>
        <div class="left-part4">
          我们是一群对音乐痴迷的技术极客；
          <br />我们热爱音乐，痴迷在音乐世界里，感受那种自由，激情，真诚与涓美；
          <br />我们热爱科技，坚信依靠科技的力量，能够把无数的不可能变成可能，把梦变成现实；
          <br />我们相信：Everyone can be a musician！
          <br />依靠音乐知识的云化，音乐创作的AI算法，每个人都可以用音乐去表达自己，音乐原本就属于每个人，过去如此，未来也如此！
        </div>
      </div>
      <div class="AI-right">
        <img src="../assets/home-image/music-ai.png" />
        <img src="../assets/home-image/music-ai-hover.png" />
      </div>
    </div>
    <div class="home-recent">
      <div class="recent-title">最新动态</div>
      <div class="title-intro">Recent News</div>
      <div class="recent-banner">
        <a-carousel arrows autoplay>
          <div slot="prevArrow" class="custom-slick-arrow" style="left: 10px;zIndex: 1">
            <i class="iconfont icon-zuixindongtaizuo" style="font-size: 30px;font-weight:500;"></i>
          </div>
          <div slot="nextArrow" class="custom-slick-arrow" style="right: 10px">
            <i class="iconfont icon-zuixindongtaiyou" style="font-size: 30px;font-weight:300;"></i>
          </div>
          <div  class="banner1-new">
            <div class="banner1-new-left">
              <img src="../assets/home-image/timbreTool.png"/>
            </div>
            <div class="banner1-new-right">
              <div class="right-title">
                <img src="../assets/home-image/timbreTool-title.png" />
              </div>
              <div class="title-intro">
                我们针对和弦派的MIDI格式开发了PC端音源，和弦派SVIP会员可以登录并在宿主使用和弦派的所有音色。<br/>
                该音源针对和弦派生成的MIDI格式进行了适配，您可以将和弦派导出的工程MIDI一键导入宿主，该音源会自动进行音色与MIDI的对应，方便您在宿主进行精细的调整与创作。
              </div>
              <div class="knowMore" @click="openTimbreTool">
                点击下载
                <i class="iconfont icon-lejiegengduo"></i>
              </div>
            </div>
          </div>
          <div class="banner1">
            <div class="banner1-left">
              <img src="../assets/home-image/chord.png" />
            </div>
            <div class="banner1-right">
              <div class="right-title">
                <img src="../assets/home-image/chord_title.png" />
              </div>
              <div class="title-intro">
                和弦派是一款基于AI音乐生成引擎，从和弦入手进行音乐创作的随身音乐工作站， 你可以在这里练习或排演任意你想演奏的歌曲，也可以借助AI的力量创作属于自己的音乐
              </div>
              <div class="knowMore" @click="openOverLay">
                立即获取
                <i class="iconfont icon-lejiegengduo"></i>
              </div>
            </div>
          </div>
          <div class="banner2">
            <div class="banner1-left">
              <img src="../assets/home-image/cutePet.png" />
            </div>
            <div class="banner1-right">
              <div class="right-title">
                <i class="iconfont icon-kou"></i>
                <i class="iconfont icon-dai"></i>
                <i class="iconfont icon-a-shandiankoudai"></i>
                <i class="iconfont icon-ledui"></i>
              </div>
              <div class="title-intro">
                你可以利用这个AI音乐工作站玩音乐，并发现创作就像游戏一样简单有趣。作曲、编曲、录唱、混音一气呵成，让更多非音乐专业的朋友也能释放自己的音乐想法。
                <br />也许你拥有音乐家的潜能，有了AI音乐引擎的支持，相信你能发现更多音乐的魅力并创造无限可能。
              </div>
              <div class="knowMore" @click="jumpApp">
                立即获取
                <i class="iconfont icon-lejiegengduo"></i>
              </div>
            </div>
          </div>
          <div class="banner3">
            <div class="banner2-left">
              <img src="../assets/home-image/bgmcat.png" />
            </div>
            <div class="banner2-right">
              <div class="right-title">
                <i class="iconfont icon-bgmmao"></i>
              </div>
              <div class="title-intro">
                这是一个AI音乐生成器，它可以<br />
                <span class="first-span">生成BGM：</span><br />
                你能快速得到和视频格调匹配的背景音乐；<br />
                <span>BGM的智能化处理：</span><br />
                它拥有调整音乐段落、高能点等关键信息的能力，轻易地同视频的节奏或事件形成准确映射。
              </div>
              <div class="knowMore" @click="jumpPage">
                了解更多
                <i class="iconfont icon-lejiegengduo"></i>
              </div>
            </div>
          </div>
        </a-carousel>
      </div>
    </div>
    <qr-code v-show="overlayFlag" @closeOverlay="closeOverlay" />
    <timbre-tool v-show="overTimbreTool" @closeTimbreTool="closeTimbreTool" />
    <div class="home-lazycomposer">
      <div class="content">
        <span>北京灵动音科技有限公司</span> |
        <a class="beianhao" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802031356"
          target="_blank">
          京公网安备 11010802031356号
        </a>｜
        <a class="beianhao" href="https://beian.miit.gov.cn" target="_blank">
          京ICP备19028267号-1
        </a>|
        <a class="beianhao"
          href="https://www.lazycomposer.com/static/%E7%BD%91%E6%96%87%E8%AF%81%E4%B9%A6_lazycomposer.com.pdf"
          target="_blank">
          京网文（2023）1075-030号
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import QrCode from './qrcode.vue';
import TimbreTool from './timbreTool.vue';
export default {
  name: 'HelloWorld',
  components: {
    QrCode,
    TimbreTool
  },
  data() {
    return {
      overlayFlag: false,
      overTimbreTool: false
    }
  },
  watch: {
    overlayFlag: function (val, oldVal) {
      if (val) {
        document.documentElement.style.overflow = "hidden";
      } else {
        document.documentElement.style.overflow = "scroll";
      }
    },
    overTimbreTool: function (val, oldVal) {
      if (val) {
        document.documentElement.style.overflow = "hidden";
      } else {
        document.documentElement.style.overflow = "scroll";
      }
    }
  },
  methods: {
    closeOverlay() {
      this.overlayFlag = false;
    },
    openOverLay() {
      this.overlayFlag = true;
    },
    openTimbreTool() {
      this.overTimbreTool = true;
    },
    closeTimbreTool() {
      this.overTimbreTool = false;
    },
    jumpApp() {
      window.open("https://apps.apple.com/cn/app/%E5%8F%A3%E8%A2%8B%E4%B9%90%E9%98%9F/id1603380354")
    },
    jumpPage() {
      window.open("https://bgmcat.com/")
    }
  }
}
</script>

<style scoped lang="less">
@media screen and (min-width: 1200px) {
  .home {
    margin-left: calc((100vw - 1200px) / 2);
  }

  .home-introduce {
    margin-left: calc((100vw - 1200px) / (-2));
  }

  .home-introduce-floatLayer {
    margin-left: calc((100vw - 1200px) / 2);
  }

}

.home {
  width: 1200px;

  .home-introduce {
    background-position: center !important;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, .3) 0%, rgba(0, 0, 0, .0) 300px, rgba(0, 0, 0, 0) 100%), url('../assets/home-image/bg.jpg');
    width: 100vw;
    min-width: 1200px;
    height: 900px;
    z-index: 0;
    border: 1px solid #141414;
    margin-top: -88px;

    .home-introduce-floatLayer {
      width: 1200px;
      height: 900px;
      padding: 0 100px 0 100px;

      .lazycomposer-company {
        .company-name1 {
          margin-top: 393px;
          font-family: Hanson;
          font-style: normal;
          font-weight: bold;
          font-size: 52px;
          line-height: 64px;
          color: rgba(255, 255, 255, 0.95);
          opacity: 0.95;
          text-shadow: 0px 4.00556px 14px rgba(252, 252, 252, 0.32);
        }

        .company-name2 {
          margin-top: -58px;
          font-family: Hanson;
          font-style: normal;
          font-weight: bold;
          font-size: 52px;
          line-height: 64px;
          -webkit-text-stroke: 1px #fff;

        }
      }

      .lazycomposer-introduce {
        width: 429px;
        margin-top: 20px;
        height: 63px;
        font-family: PingFang SC;
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 21px;
        color: rgba(255, 255, 255, 0.65);
      }

      .xialajiantou {
        float: right;
        margin-top: 150px;
        margin-right: -84px;

        img {
          width: 8px;
          height: 97px;
        }
      }
    }
  }

  .home-music-AI {
    padding: 0 100px 0 100px;
    height: 900px;
    color: #fff;
    display: flex;

    .AI-left {
      width: 440px;
      margin-right: 67px;

      .left-part1 {
        margin-top: 172px;
        font-family: PangMenZhengDao;
        font-style: normal;
        font-weight: normal;
        font-size: 56px;
        line-height: 44px;
        color: rgba(255, 255, 255, 0.95);
      }

      .left-part2 {
        margin-top: 16px;
        font-family: Hanson;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        color: rgba(255, 255, 255, 0.65);
      }

      .left-part3 {
        margin-top: 182.5px;
        font-family: PingFang SC;
        font-style: normal;
        font-weight: 200;
        font-size: 22px;
        line-height: 32px;
        display: flex;
        color: rgba(255, 255, 255, 0.85);
      }

      .left-part4 {
        margin-top: 16px;
        font-family: PingFang SC;
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 21px;
        color: rgba(255, 255, 255, 0.65);
      }
    }

    .AI-right {
      margin-top: 208px;
      height: 572px;

      img {
        width: 493px;
        height: 572px;
      }

      img:nth-child(1) {
        display: block;
      }

      img:nth-child(2) {
        display: none;
      }
    }

    .AI-right:hover {
      img:nth-child(2) {
        display: block;
      }

      img:nth-child(1) {
        display: none;
      }
    }
  }

  .home-recent {
    padding: 168px 0px 0 0px;
    height: 900px;

    .recent-title {
      margin-left: 100px;
      font-family: PangMenZhengDao;
      font-style: normal;
      font-weight: normal;
      font-size: 56px;
      line-height: 44px;
      color: rgba(255, 255, 255, 0.95);
      opacity: 0.95;
    }

    .title-intro {
      margin-left: 100px;
      margin-top: 16px;
      font-family: Hanson;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      color: rgba(255, 255, 255, 0.65);
      opacity: 0.95;
    }

    .recent-banner {
      height: 614px;
      margin-top: 30px;
      .banner1-new{
        display: flex !important;
        float: left;
        padding-left: 100px;
        .banner1-new-left{
          z-index: 10;
          width: 595px;
          height: 500px;

          img {
            width: 595px;
            width: 556px;
            height: 500px;
          }
        }
        .banner1-new-right {
          margin-top: 150px;
          margin-left: 124.6px;
          width: 280px;

          .right-title {
            img {
              width: 218px;
            }
          }

          .title-intro {
            margin-left: 0px;
            width: 280px;
            margin-top: 22px;
            font-family: PingFang SC;
            font-style: normal;
            font-weight: 300;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.2px;
            color: rgba(255, 255, 255, 0.65);
          }

          .knowMore {
            cursor: pointer;
            margin-top: 68px;
            width: 164px;
            height: 60px;
            background: #303030;
            text-align: center;
            border-radius: 40px;
            font-family: PingFang SC;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 60px;
            color: #5A85FD;
          }
        }
      }
      .banner1 {
        display: flex !important;
        float: left;
        padding-left: 100px;

        .banner1-left {
          width: 595px;
          height: 500px;

          img {
            width: 595px;
            height: 500px;
          }
        }

        .banner1-right {
          margin-top: 150px;
          margin-left: 124.6px;
          width: 280px;

          .right-title {
            img {
              width: 120px;
            }
          }

          .title-intro {
            margin-left: 0px;
            width: 280px;
            margin-top: 16px;
            font-family: PingFang SC;
            font-style: normal;
            font-weight: 300;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.2px;
            color: rgba(255, 255, 255, 0.65);
          }

          .knowMore {
            cursor: pointer;
            margin-top: 72px;
            width: 164px;
            height: 60px;
            background: #303030;
            text-align: center;
            border-radius: 40px;
            font-family: PingFang SC;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 60px;
            color: #5A85FD;
          }
        }
      }

      .banner2 {
        display: flex !important;
        float: left;
        padding-left: 100px;

        .banner1-left {
          width: 595px;
          height: 500px;

          img {
            width: 595px;
            height: 500px;
          }
        }

        .banner1-right {
          margin-top: 150px;
          margin-left: 124.6px;
          width: 280px;

          .right-title {
            color: #fff;
            height: 28px;

            i {
              font-size: 22px;
            }

            i:nth-child(3) {
              color: #5A85FD;
              font-size: 20px;
            }
          }

          .title-intro {
            margin-left: 0px;
            width: 280px;
            margin-top: 16px;
            font-family: PingFang SC;
            font-style: normal;
            font-weight: 300;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.2px;
            color: rgba(255, 255, 255, 0.65);
          }

          .knowMore {
            cursor: pointer;
            margin-top: 32px;
            width: 164px;
            height: 60px;
            background: #303030;
            text-align: center;
            border-radius: 40px;
            font-family: PingFang SC;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 60px;
            color: #5A85FD;
          }
        }
      }

      .banner3 {
        display: flex !important;
        float: left;

        .banner2-left {
          margin-top: 90px;
          z-index: 10;
          width: 680px;
          height: 348px;

          img {
            width: 680px;
            height: 348px;
          }
        }

        .banner2-right {
          margin-top: 136px;
          margin-left: 124.6px;
          float: left;
          width: 280px;

          .right-title {
            color: #fff;
            font-size: 23px;
            height: 28px;

            i {
              font-size: 26px;
            }
          }

          .title-intro {
            margin-left: 0px;
            width: 280px;
            margin-top: 32px;

            span {
              font-weight: normal;
            }

            .first-span {
              display: inline-block;
              margin-top: 20px !important;
            }

            font-family: PingFang SC;
            font-style: normal;
            font-weight: 300;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.2px;
            color: rgba(255, 255, 255, 0.65);
          }

          .knowMore {
            cursor: pointer;
            margin-top: 32px;
            width: 164px;
            height: 60px;
            background: #303030;
            text-align: center;
            border-radius: 40px;
            font-family: PingFang SC;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 60px;
            color: #5A85FD;
          }
        }
      }
    }
  }


  .home-lazycomposer {
    height: 88px;
    color: rgba(255, 255, 255, 0.45);
    text-align: center;

    .content {
      margin-top: 34px;
      font-family: PingFang SC;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 20px;

      span {
        margin: 0 8px;
      }

      a {
        color: rgba(255, 255, 255, 0.45);
        margin: 0 8px;
      }
    }
  }

  .home-bottom {
    overflow: hidden;
    padding: 0 100px 0 100px;
    position: relative;
    height: 1232px;

    .background-img {
      position: absolute;
      z-index: 0;

      img {
        width: 780px;
        height: 897px;
        margin-left: 100px;
        margin-top: 130px;
        transform: rotate(30deg);
      }
    }

    .bottom-container {
      position: absolute;
      z-index: 100;

      .home-partner {
        z-index: 10 !important;
        height: 532px;
        overflow: hidden;

        .partner-title {
          margin-top: 170px;
          font-family: PangMenZhengDao;
          font-style: normal;
          font-weight: normal;
          font-size: 56px;
          line-height: 44px;
          text-align: right;
          color: rgba(255, 255, 255, 0.95);
          opacity: 0.95;
        }

        .title-inter {
          margin-top: 16px;
          font-family: Hanson;
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 24px;
          text-align: right;
          color: rgba(255, 255, 255, 0.65);
          opacity: 0.95;
        }

        .partner-content {
          margin-top: 76px;
          font-size: 30px;
          line-height: 160px;
          height: 160px;
          color: #fff;
          display: flex;
          float: right;

          .content {
            margin: 0 30px;
          }
        }
      }

      .home-musican {
        color: #fff;
        height: 532px;
        overflow: hidden;

        .musican-title {
          margin-top: 170px;
          font-family: PangMenZhengDao;
          font-style: normal;
          font-weight: normal;
          font-size: 56px;
          line-height: 44px;
          display: flex;
          align-items: center;
          color: rgba(255, 255, 255, 0.95);
          opacity: 0.95;
        }

        .title-intro {
          margin-top: 16px;
          font-family: Hanson;
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 24px;
          color: rgba(255, 255, 255, 0.65);
          opacity: 0.95;
        }

        .musican-content {
          display: flex;
          margin-top: 90px;

          .content {
            margin-right: 32px;

            img {
              width: 80px;
              height: 80px;
              border-radius: 100%;
              border: 4px solid rgb(104, 142, 241);
            }

            .musican-name {
              margin-top: 16px;
              width: 224px;
              font-family: PingFang SC;
              font-style: normal;
              font-weight: bold;
              font-size: 18px;
              line-height: 28px;
              font-feature-settings: 'salt' on, 'liga' off;
              color: rgba(255, 255, 255, 0.65);
            }

            .musican-describe {
              margin-top: 4px;
              width: 224px;
              font-family: PingFang SC;
              font-style: normal;
              font-weight: normal;
              font-size: 16px;
              line-height: 26px;
              font-feature-settings: 'salt' on, 'liga' off;
              color: rgba(255, 255, 255, 0.65);
            }
          }

        }
      }

      .home-lazycomposer {
        height: 168px;
        color: rgba(255, 255, 255, 0.45);
        text-align: center;

        .content {
          margin-top: 114px;
          font-family: PingFang SC;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 20px;

          span {
            margin: 0 8px;
          }

          a {
            color: rgba(255, 255, 255, 0.45);
            margin: 0 8px;
          }
        }
      }
    }
  }
}
</style>
<style scoped>
/* For demo */
.ant-carousel>>>.slick-slide {
  overflow: hidden;
}

.ant-carousel>>>.custom-slick-arrow {
  color: rgba(255, 255, 255, 0.45);
  margin-top: -50px;
}

.ant-carousel>>>.custom-slick-arrow:before {
  display: none;
}

.ant-carousel>>>.slick-dots {
  width: 60px;
}

.ant-carousel>>>.slick-dots-bottom {
  left: 735px !important;
  bottom: -30px !important;
}

.ant-carousel>>>li button {
  border-radius: 10px !important;
  width: 5px !important;
  margin: 0 3px !important;
  height: 10px !important;
  background: #fff !important;
}

.ant-carousel>>>.slick-active button {
  height: 20px !important;
  margin-top: -10px !important;
}
</style>
